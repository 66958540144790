#frame {
	margin: auto;
	padding: 0;
	width: 100vw;
	height: 100%;
	justify-content: left;
	display: flex;
	flex-direction: column;
	align-items: center;
	border-color: 'rgba(69, 111, 95, 1)';
	background-color: rgb(69, 111, 95);
	max-width: 740px;
}

canvas {
	position: absolute;
	margin-top: 5px;
	padding: 0;
	align-items: center;
}

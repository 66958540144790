#logoposition {
	height: 90vh;
	margin-top: 3vh;
}

#riusLogo {
	width: 50%;
	min-width: 200px;
	top: 15%;
	left: 25%;
	position: absolute;
	mix-blend-mode: normal;
}

@media only screen and (max-width: 980px) {
	#logoposition {
		height: 45vh;
		min-width: 350px;
	}
}

@media screen and (min-width: 640px) and (-webkit-device-pixel-ratio: 3) {
	#riusLogo {
		width: 80%;
		min-width: 200px;
		left: 20%;
		position: absolute;
		mix-blend-mode: normal;
	}

	#logoposition {
		height: 30vh;
		min-width: 450px;
		min-height: 450px;
	}

	#riusLogo {
		width: 70%;
		top: 20%;
		left: 150%;
	}
}
@media only screen and (min-width: 768px) {
}

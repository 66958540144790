body {
	background-color: #e1e1e1;
	min-height: 800px;
}

.navbar {
	display: flex;
	height: 7vh;
	justify-content: space-between;
	line-height: 25px;
	list-style-type: none;
	position: sticky;
	z-index: 2;
	background-color: #e1e1e1;
	top: 0;
	box-shadow: 0 8px 6px -6px black;
}

.navbar > * {
	margin: 2vh 5vw 5vh 5vw;
	font-size: 40px;
	color: #456f5f;
}

ul {
	margin: 0;
	width: 100%;
	padding: 0;
}

.navbar > div {
	display: flex;
	justify-content: space-between;
}

div > ul {
	margin: 10 10;
}

#divider {
	padding-left: 0.5em;
	padding-right: 0.5em;
	font-size: 0.7em;
	max-height: 20px;
}

.navbar a {
	text-decoration: none;
	color: #456f5f;
	font-size: 35px;
	line-height: 18px;
}

a:hover {
	text-decoration: underline;
}

.active {
	text-decoration: underline !important;
}

a {
	color: #456f5f;
}

@media screen and (max-width: 850px) {
	.navbar a {
		font-size: 20px;
	}

	.navbar {
		font-size: 3vw;
	}

	.navbar > * {
		font-size: 25px;
	}
}

@media screen and (min-width: 640px) and (-webkit-device-pixel-ratio: 3) {
	.navbar a {
		font-size: 1.3em;
	}

	#divider {
		font-size: 1.7em;
	}
}

@media screen and (min-width: 640px) and (-webkit-device-pixel-ratio: 3) {
	.navbar {
		display: flex;
		height: 6vh;
		margin-bottom: 4vh;
		justify-content: space-between;
		line-height: 15px;
		z-index: 20;
		list-style-type: none;
		position: sticky;
		background-image: linear-gradient(
			#e1e1e1,
			#e1e1e1,
			#e1e1e1,
			#e1e1e1,
			#e1e1e1,
			rgba(255, 255, 255, 0)
		);
		top: 0;
	}
}

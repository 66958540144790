.projectImg {
	height: 150px;
	width: 150px;
	position: relative;
}

#image {
	margin-top: 1vh;
	padding: 5px;
	border-radius: 10%;
	box-shadow: none;
	max-width: 210px;
	margin: auto;
}

#link_project {
	color: black;
}

.col-md-projects {
	padding-top: 5vh;
	background-color: #82aa9a;
	border-radius: 10px;
}

.col-md-projects:nth-child(2n) {
	background-color: #e6d5cb;
	border-radius: 10px;
}

.col-md-projects:first-child {
	padding-top: 5vh;
}

#project {
	background-color: #e1e1e1;
	padding-top: 50px;
}

#about {
  width: 100%;
  height: 100%;
}

#visittkort {
  position: absolute;
  width: 80vw;
  max-width: 800px;
  max-height: auto;
  top: 25vh;
  left: 10%;
  margin: 20% 10% 10% 10% auto;
  text-align: center;
  box-shadow: 5px 5px 10px 0px #456f5f;
  margin: 0 auto;
}

#kontaktinfo {
  background-color: orange;
  position: absolute;
  left: 50%;
  width: 35%;
  top: 20%;
  height: 30vh;

}



@media screen and (min-width: 1000px) {
  #visittkort {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 800px;
    margin-top: -200px;
    /* Half the height */
    margin-left: -400px;
    /* Half the width */
  }
}

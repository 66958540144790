#riusLogo {
	width: 50%;
	left: 25%;
	position: absolute;
	mix-blend-mode: normal;
}

.box-area {
	position: absolute;
	top: 5%;
	left: 0;
	width: 100%;
	height: 95%;
	overflow: hidden;
}

.box-area li {
	position: absolute;
	display: block;
	list-style: none;
	width: 25px;
	height: 25px;
	background-color: #f0803c;
	opacity: 0.2;
	animation: animate 20s linear infinite;
	bottom: -150px;
	-moz-box-shadow: inset 0 0 10px #000000;
	-webkit-box-shadow: inset 0 0 10px #000000;
	box-shadow: inset 0 0 10px #000000;
}

.box-area li:hover {
	background-color: #456f5f;
	-webkit-animation-play-state: paused;
	/*   -moz-animation-play-state: paused;
    -o-animation-play-state: paused;
    animation-play-state: paused; */
}

.box-area li:nth-child(0) {
	left: 20%;
	width: 400px;
	height: 40px;
	animation-delay: 3s;
	animation-duration: 7s;
}

.box-area li:nth-child(1) {
	left: 73%;
	width: 80px;
	height: 80px;
	animation-delay: 3s;
	border-radius: 40%;
	animation-duration: 9s;
}

.box-area li:nth-child(2) {
	left: 25%;
	width: 30px;
	height: 30px;
	animation-delay: 1.5s;
	border-radius: 40%;
	animation-duration: 8s;
}

.box-area li:nth-child(3) {
	left: 3%;
	width: 100px;
	height: 100px;
	border-radius: 30%;
	animation-delay: 4s;
	animation-duration: 12s;
}

.box-area li:nth-child(4) {
	left: 90%;
	width: 120px;
	height: 120px;
	border-radius: 40%;
	animation-delay: 0s;
	animation-duration: 15s;
}

.box-area li:nth-child(5) {
	left: 22%;
	width: 25px;
	height: 25px;
	border-radius: 30%;
	animation-delay: 5s;
	animation-duration: 8s;
}

.box-area li:nth-child(6) {
	left: 60%;
	width: 55px;
	height: 55px;
	border-radius: 40%;
	animation-delay: 2s;
	animation-duration: 15s;
}

@media only screen and (max-width: 992px) {
	.box-area {
		height: 50vh;
	}

	.box-area li {
		animation: animate-small 20s linear infinite;
	}
}

@media screen and (min-width: 640px) and (-webkit-device-pixel-ratio: 3) {
	.box-area {
		min-height: 450px;
	}
}

@keyframes animate {
	0% {
		transform: translateY(0) rotate(0deg);
		opacity: 0.8;
	}

	100% {
		transform: translateY(-80vh) rotate(720deg);
		opacity: 0;
	}
}

@keyframes animate-small {
	0% {
		transform: translateY(0) rotate(0deg);
		opacity: 0.8;
	}

	100% {
		transform: translateY(-50vh) rotate(720deg);
		opacity: 0;
	}
}

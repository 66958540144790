h2 {
	padding: 0;
	margin: 0;
	padding: 0;
	margin: 0;
}

#home {
	-moz-box-shadow: inset 0 0 10px #000000;
	-webkit-box-shadow: inset 0 0 10px #000000;
	box-shadow: inset 0 0 10px #000000;
	padding: 0;
	padding-top: 50px;
	background-color: #e6d5cb;
	overflow: hidden;
}

.col-md-7 {
	display: flex;
	flex-direction: row;
	padding: 3vh;
	width: 50vw;
	min-width: 600px;
	margin: auto;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
	margin-bottom: 20px;
	transition: 0.3s;
	background-color: #ece2dc;
}

.col-md-7 img {
	margin: 20px;
	-moz-box-shadow: inset 0 0 10px #000000;
	-webkit-box-shadow: inset 0 0 10px #000000;
	box-shadow: 0 0 10px #000000;
	display: block;
	max-width: 350px;
	max-height: 200px;
	width: auto;
	height: auto;
}

.text-home {
	max-width: 400px;
	margin: auto;
}
.invertedColumn {
	flex-direction: row-reverse;
}

.col-md-7:hover {
	box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.2);
}

/* Extra small devices (phones, 600px and down) */
@media (max-width: 600px) {
	h2 {
		font-size: 1.5rem;
	}
	p {
		font-size: 1rem;
	}
	.col-md-7 {
		flex-direction: column-reverse;
		padding: 3vh;
		width: 50vw;
		min-width: 300px;
		margin: auto;
		margin-bottom: 40px;
	}
	.col-md-7 img {
		margin: auto;
		max-width: 300px;
		height: auto;
		width: auto;
	}

	.invertedColumn {
		flex-direction: column-reverse;
	}
	#riusLogo {
		top: 15%;
	}
	#logoposition {
		height: 30vh;
		min-width: 450px;
	}
	.box-area {
		height: 35vh;
	}
	.text-home {
		max-width: 800px;
		margin: auto;
	}
}

/* Small devices (portrait tablets and large phones, 600px and up) */

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
	#section {
		position: relative;
		text-align: center;
		height: 45vh;
		min-width: 300px;
	}

	#section > img {
		width: 30%;
		height: auto;
		margin-top: 3%;
	}

	h2 {
		font-size: 1.4em;
		margin-top: 20px;
	}

	#section > p {
		font-size: 1.3em;
	}
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
	#section {
		position: relative;
		text-align: center;
		width: 100%;
		height: 100vh;
		min-width: 300px;
		font-size: 1.4em;
	}

	#section > img {
		width: 40%;
	}

	h2 {
		font-size: 1.4em;
		margin-top: 20px;
	}

	#logoposition {
		height: 90vh;
		min-width: 450px;
	}
}

@media screen and (min-width: 640px) and (-webkit-device-pixel-ratio: 3) {
	h2 {
		font-size: 3rem;
	}
	p {
		font-size: 2.8rem;
	}
	.col-md-7 {
		flex-direction: column-reverse;
		padding: 3vh;
		width: 50vw;
		min-width: 600px;
		margin: auto;
		margin-bottom: 5vh;
	}
	.col-md-7 img {
		margin: auto;
		max-width: 800px;
		height: auto;
		width: auto;
		margin-bottom: 20px;
		max-height: 400px;
	}
	.invertedColumn {
		flex-direction: column-reverse;
	}
	#riusLogo {
		top: 15%;
	}
	#logoposition {
		height: 30vh;
		min-width: 450px;
	}
	.box-area {
		height: 35vh;
	}
	.text-home {
		max-width: 800px;
		margin: auto;
	}
}

@keyframes pulse-orange {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(255, 121, 63, 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(255, 121, 63, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(255, 121, 63, 0);
	}
}

@keyframes pulse-orange-big {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(255, 121, 63, 0.7);
	}

	70% {
		transform: scale(0.75);
		box-shadow: 0 0 0 15px rgba(255, 121, 63, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(255, 121, 63, 0);
	}
}

@keyframes pulse-green {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(69, 111, 95, 0.8);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(69, 111, 95, 0.8);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(69, 111, 95, 0.8);
	}
}
